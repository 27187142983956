<template>
  <div>
    <b-card
      bg-variant="light"
      header="Mis Datos Personales"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <b-form
        @submit="modalEnviar"
        align="left"
        id="formularioResiduos"
      >
        <b-form-row>
          <b-col cols="12" md="4">
            <b-form-group
              id="group-nombres"
              label="Nombres"
              label-for="nombres"
            >
              <b-form-input
                id="nombres"
                v-model="form.nombres"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-apellidos"
              label="Apellidos"
              label-for="apellidos"
            >
              <b-form-input
                id="apellidos"
                v-model="form.apellidos"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <label for="fecha_nacimiento">Fecha Nacimiento:</label>
            <b-form-datepicker 
              id="fecha_nacimiento" 
              v-model="form.fecha_nacimiento" 
              placeholder="YYYY/MM/DD"  
              locale="es"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              labelNoDateSelected="No hay fecha"
              labelHelp="Use las flechas del teclado para navegar entre fechas"
            >
            </b-form-datepicker>
            </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-ocupacion"
              label="Ocupación"
              label-for="ocupacion"
            >
              <b-form-input
                id="ocupacion"
                v-model="form.ocupacion"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-identificacion"
              label="Identificación"
              label-for="identificacion"
            >
              <b-form-input
                id="identificacion"
                v-model="form.identificacion"
                disabled
                
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-contrasena"
              label="Nueva Contraseña"
              label-for="contrasena"
            >
              <b-form-input
                id="contrasena"
                v-model="form.contrasena"
                placeholder="Ingrese nueva contraseña"
                type="password"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-direccion"
              label="Dirección"
              label-for="direccion"
            >
              <b-form-input
                id="direccion"
                v-model="form.direccion"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-finca_sector"
              label="Finca sector"
              label-for="finca_sector"
            >
              <b-form-input
                id="finca_sector"
                v-model="form.finca_sector"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-barrio_vereda"
              label="Barrio vereda"
              label-for="barrio_vereda"
            >
              <b-form-input
                id="barrio_vereda"
                v-model="form.barrio_vereda"
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-telefono"
              label="Teléfono"
              label-for="telefono"
            >
              <b-form-input
                id="telefono"
                v-model="form.telefono"
                required
                :disabled="verDetalles ? true : false"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              id="group-email"
              label="email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="text-right">
              <b-button class="mr-3" type="submit"> Guardar </b-button>
            </div>
          </b-col>

        </b-form-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { encontrarError } from '../../../contantesErrores';

export default {
  name: "MisDatosPersonales",
  data() {
    return {
      persona: {},
      verDetalles: false,
      form:{
        id: null,
        nombres: null,
        apellidos: null,
        fecha_nacimiento: null,
        ocupacion: null,
        identificacion: null,
        contrasena: null,
        direccion: null,
        finca_sector: null,
        barrio_vereda: null,
        telefono: null,
        email: null
      }
    };
  },
  async mounted() {
    if (localStorage.getItem("persona")) {
      try {
        this.persona = JSON.parse(localStorage.getItem("persona"));
        console.log('esto es la persona', this.persona)
      } catch (e) {
        localStorage.removeItem("persona");
      }

      try {
        await this.getPersonaById(this.persona.id_persona)
        this.mapearDatosPersona()
      } catch (e) {
        encontrarError(this, e.data)
      }
    }
  },
  computed: {
    ...mapState("personas", ["personaCargada", "resultCreatePersona"]),
  },
  methods: {
    ...mapActions("personas", ["getPersonaById","editPersona"]),

    mapearDatosPersona(){
      this.form = {...this.personaCargada}
      this.form.contrasena = null
      let fecha = this.form.fecha_nacimiento.split('T')
      if(fecha[0] == "1900-01-01"){
        console.log('aqui carnal')
        this.form.fecha_nacimiento = null
      }
    },
    
     modalEnviar(event) {
      event.preventDefault();
      if(this.form.fecha_nacimiento != null){
        this.$bvModal
          .msgBoxConfirm("Se van a guardar los datos, ¿Desea continuar?.", {
            title: "Por favor confirme",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: "Continuar",
            cancelTitle: "Cancelar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.enviarFormulario();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        this.modalMensaje("La fecha de nacimiento debe estar diligenciada")
      }
    },

    async enviarFormulario(){
      if(this.form.contrasena==null || this.form.contrasena==''){
        delete this.form.contrasena
      }
      let datosGuardar = {
        persona: this.form
      }
      console.log('datosGuardar', datosGuardar)
      try{
        await this.editPersona(datosGuardar)
        if(this.resultCreatePersona.data.status == 200){
          this.persona.nombre_persona = `${this.form.nombres} ${this.form.apellidos}`
          this.persona.fecha_nacimiento= this.form.fecha_nacimiento
          this.persona.ocupacion= this.form.ocupacion
          this.persona.direccion= this.form.direccion
          this.persona.finca_sector= this.form.finca_sector
          this.persona.barrio_vereda= this.form.barrio_vereda
          this.persona.telefono= this.form.telefono
          let persona = JSON.stringify(this.persona);
          localStorage.setItem("persona", persona);
          console.log('esto es la persona despues de guardada', this.persona)
          this.modalOk()
        }
      }catch(e){
        encontrarError(this, e.data)
      }
    },

    modalOk() {
      this.$bvModal
        .msgBoxOk("Los Datos fueron guardados correctamente.", {
          title: "Confirmación",
          size: "md",
          buttonSize: "md",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modalMensaje(mensaje) {
      this.$bvModal
        .msgBoxOk(mensaje, {
          title: "Error",
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then(() => {
          console.log("creado");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>